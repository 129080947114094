@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Jost&family=Poppins&display=swap');

body {
  margin: 0;
  font-family: 'Jost', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Jost', sans-serif;
}

.hi{
  height: max-content;
}
